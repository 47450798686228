/* eslint-disable max-len */

export const IGNORED_401_URLS = [
  '/auth/enabled-2fa',
  '/auth/login',
  `${import.meta.env.VITE_TRANSLATION_SERVICE_URL}/translate`,
  `${import.meta.env.VITE_TRANSLATION_SERVICE_URL}/locales`,
  '/v1/external_user_whisper_tokens/',
  '/v1/external_user_whispers/',
] as const

export enum FEATURE_FLAG_ENUM {
  ANALYTICS_ADVANCED_EDITOR_ENABLED = 'ANALYTICS_ADVANCED_EDITOR_ENABLED',
  ANTI_SNOOP_MODE_ENABLED = 'ANTI_SNOOP_MODE_ENABLED',
  CORE_WEB_V2_BETA_OPT_IN_ENABLED = 'CORE_WEB_V2_BETA_OPT_IN_ENABLED',
  CORE_WEB_V2_ENABLED = 'CORE_WEB_V2_ENABLED',
  GUEST_ACCESS_ENABLED = 'GUEST_ACCESS_ENABLED',
  HIDE_UNOWNED_REPORTS_ENABLED = 'HIDE_UNOWNED_REPORTS_ENABLED',
  INTERNAL_FORM = 'INTERNAL_FORM',
  LANDING_PAGE_DISABLE_MOBILE = 'LANDING_PAGE_DISABLE_MOBILE',
  MOBILE_APP_RELEASED = 'MOBILE_APP_RELEASED',
  MOBILE_MANUAL_LOGIN_DISABLED = 'MOBILE_MANUAL_LOGIN_DISABLED',
  NAME_GENERATOR_ANIMAL = 'NAME_GENERATOR_ANIMAL',
  READ_RECEIPTS_ENABLED = 'READ_RECEIPTS_ENABLED',
  RUNTIME_TRANSLATIONS = 'RUNTIME_TRANSLATIONS',
  SSO_CERTIFICATE_UPDATED = 'SSO_CERTIFICATE_UPDATED',
  SSO_WHISPLI_MANAGED_ROLE = 'SSO_WHISPLI_MANAGED_ROLE',
  WHISPLI_CORE_ENABLED = 'WHISPLI_CORE_ENABLED',
  WHISPLI_PULSE_ENABLED = 'WHISPLI_PULSE_ENABLED',
  WHISPLI_PULSE_SLACK_ENABLED = 'WHISPLI_PULSE_SLACK_ENABLED',
  WHISPLI_PULSE_TEAMS_ENABLED = 'WHISPLI_PULSE_TEAMS_ENABLED',
  API_WHISPER_REPORTS_LIST_ENABLED = 'API_WHISPER_REPORTS_LIST_ENABLED',
  HOTLINE = 'HOTLINE',
  AI_FEATURES = 'AI_FEATURES',
  MULTIPLE_WORKFLOWS = 'MULTIPLE_WORKFLOWS',
  PARTICIPANTS_ENABLED = 'PARTICIPANTS_ENABLED',
  VOICE_RECORDING_ENABLED = 'VOICE_RECORDING_ENABLED',
}

export enum USER_TYPE_ENUM {
  SYSTEM = 'system',
  SYSTEM_USER = 'system-user',
  USER = 'user',
  INFORMANT = 'whisperer',
  /** @deprecated Use USER_TYPE_ENUM.INFORMANT */
  WHISPERER = 'whisperer',
  AUTOMATION = 'automation',
  AUTOMATION_GROUP = 'automation-group',
  UNKNOWN = '',
}

export { USER_TYPE_ENUM as UserType }

/**
 * Map user types with their intended 'message receiver' user types.
 *
 * @example
 *   // messages sent by an INFORMANT are intended for USERs (case managers).
 *   [USER_TYPE_ENUM.INFORMANT]: USER_TYPE_ENUM.USER
 */
export const USER_TYPE_MAP = {
  [USER_TYPE_ENUM.INFORMANT]: USER_TYPE_ENUM.USER,
  [USER_TYPE_ENUM.USER]: USER_TYPE_ENUM.INFORMANT,
  [USER_TYPE_ENUM.AUTOMATION]: USER_TYPE_ENUM.INFORMANT,
  [USER_TYPE_ENUM.AUTOMATION_GROUP]: USER_TYPE_ENUM.INFORMANT,
}

export enum USER_STATUS_ENUM {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

/**
 * @deprecated use MessageTypeEnum from openapi/client/constants
 * @link packages/client/src/tenant/openapi/client/constants.ts
 */
export enum MESSAGE_TYPE_ENUM {
  GENERAL = 'general',
  NOTE = 'note',
}

export { MESSAGE_TYPE_ENUM as MessageType }

export enum MESSAGE_STATUS_ENUM {
  PENDING = 'PENDING',
  DELIVERED = 'DELIVERED',
  READ = 'READ',
  DELETED = 'DELETED',
}

export { MESSAGE_STATUS_ENUM as MessageStatus }

export enum MESSAGE_SORT_ENUM {
  CREATED_AT_ASC = 'created_at.asc',
  CREATED_AT_DESC = 'created_at.desc',
}

export { MESSAGE_SORT_ENUM as MessageSort }

/**
 * @note Entity names corresponding to Tenant API entities
 */
export enum MODEL_TYPE_ENUM {
  REPORT = 'whisper',
  PROJECT = 'project',
  ANSWER_MEDIA = 'answer-media'
}

export enum REPORT_STATUS_ENUM {
  DRAFT = 'Draft',
  SUBMITTED = 'Submitted',
  PENDING = 'Pending',
  CLOSED = 'Closed',
  ARCHIVED = 'Archived',
  DELETED = 'Deleted',
  UNKNOWN = 'Unknown',
}

export { REPORT_STATUS_ENUM as ReportStatus }

export enum CHANNEL_TYPE_ENUM {
  FORM = 'form',
  INTERNAL_FORM = 'internal-form',
  HOTLINE = 'hotline',
  CHAT = 'chat',
  PULSE = 'pulse',
}

export { CHANNEL_TYPE_ENUM as ChannelType }

export enum FILE_SCAN_STATUS_ENUM {
  IN_PROGRESS = 'in_progress',
  CLEAN = 'clean',
  INFECTED = 'infected',
}

export { FILE_SCAN_STATUS_ENUM as FileScanStatus }

export enum FILE_PREVIEW_STATUS_ENUM {
  UNSUPPORTED = 'unsupported',
  PENDING = 'pending',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export { FILE_PREVIEW_STATUS_ENUM as FilePreviewStatus }

export enum VOICE_SERVICE_STATUS_ENUM {
  PENDING = 'pending',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export { VOICE_SERVICE_STATUS_ENUM as VoiceServiceStatus }

export enum FILE_FILTERABLE_TYPE_ENUM {
  REPORT = 'report',
  CHAT = 'chat',
  WORKFLOW = 'workflow',
  PARTICIPANT = 'participant'
}

export { FILE_FILTERABLE_TYPE_ENUM as FileFilterableType }

export enum ANSWERABLE_TYPE_ENUM {
  COUNTRY = 'answer-country',
  DATE = 'answer-date',
  MEDIA = 'answer-media',
  NUMBER = 'answer-number',
  VOICE = 'answer-voice',
  OPTION = 'element-option',
  TEXT = 'answer-text',
  USER = 'user',
  ANSWER_ELEMENT_BOOLEAN_OPTION = 'answer-element-boolean-option',
  ANSWER_ELEMENT_DYNAMIC_OPTION = 'answer-element-dynamic-option',
  ANSWER_ELEMENT_SCALE_OPTION = 'answer-element-scale-option',
}

export { ANSWERABLE_TYPE_ENUM as AnswerType }

export enum ELEMENT_TYPE_ENUM {
  ACTIVE_DIRECTORY = 'active-directory',
  CHECKBOX = 'checkbox',
  COUNTRY = 'country',
  DATE = 'date',
  DROPDOWN = 'dropdown',
  MEDIA = 'media',
  MULTI_DROPDOWN = 'multi-dropdown',
  NUMBER = 'number',
  RADIO = 'radio',
  STATEMENT = 'statement',
  TEXT = 'text',
  TEXTAREA = 'textarea',
  USER_LIST = 'user-list',
  VOICE_MESSAGE = 'voice-message',
  DYNAMIC_LIST = 'dynamic-list',
  SCALE = 'scale', // Pulse
  BOOLEAN = 'boolean', // Pulse
}

export { ELEMENT_TYPE_ENUM as ElementType }

export enum MEMBERSHIPABLE_TYPE_ENUM {
  USERS = 'user',
  USER_GROUPS = 'user-group',
  EXTERNAL_USERS = 'external-user',
}

export enum MEMBERSHIPABLE_SOURCE_TYPE_ENUM {
  USERS = 'user',
  AUTOMATION = 'automation'
}

export enum MEMBERABLE_TYPE_ENUM {
  // @todo Fix API response
  DASHBOARDS = 'App\\Dashboard',
  PROJECTS = 'projects',
  REPORTS = 'whispers',
}

export enum AUTOMATION_TYPE_ENUM {
  EVENT = 'event',
  GROUP_EVENT = 'group-event',
  TIME = 'time',
  GROUP_TIME = 'group-time',
}

export const AUTOMATION_TYPES = Object.freeze(Object.values(AUTOMATION_TYPE_ENUM))

export enum TRIGGER_TYPE_ENUM {
  WHISPER_SUBMITTED = 'whisper_submitted',
  PROJECT_CREATED = 'project_created',
  WHISPER_WORKFLOW_STEP_SUBMITTED = 'whisper_workflow_step_submitted',
  PROJECT_WORKFLOW_STEP_SUBMITTED = 'project_workflow_step_submitted',
  WHISPER_TAGGED = 'whisper_tagged',
  PROJECT_TAGGED = 'project_tagged',
  WHISPER_STATUS_UPDATED = 'whisper_status_updated',
  PROJECT_STATUS_UPDATED = 'project_status_updated',
  WHISPER_WORKFLOW_COMPLETED = 'whisper_workflow_completed',
  PROJECT_WORKFLOW_COMPLETED = 'project_workflow_completed',
  WHISPER_WORKFLOW_STEP_EDITED = 'whisper_workflow_step_edited',
  PROJECT_WORKFLOW_STEP_EDITED = 'project_workflow_step_edited'
}

export const TRIGGER_TYPES = Object.freeze(Object.values(TRIGGER_TYPE_ENUM))

export const GROUP_TRIGGER_TYPES = Object.freeze(TRIGGER_TYPES
  .filter((e) => e !== TRIGGER_TYPE_ENUM.PROJECT_CREATED))

/** @note API conflates triggers and condition types */
export enum TIMED_TRIGGER_TYPE_ENUM {
  TIME_BASED_WHISPER_SUBMITTED = 'time_based_whisper_submitted',
  CONVERSATION_IS_INACTIVE_BY_CASE_MANAGER = 'conversation_is_inactive_by_case_manager'
}

export const TIMED_TRIGGER_TYPES = Object.freeze(Object.values(TIMED_TRIGGER_TYPE_ENUM))

export enum ACTION_TYPE_ENUM {
  NOTIFY_USERS = 'notify_users',
  ADD_TAGS = 'add_tags',
  ADD_WORKFLOW = 'add_workflow',
  ADD_ADDITIONAL_WORKFLOW = 'add_additional_workflow',
  ADD_MEMBERS = 'add_members',
  REMOVE_MEMBERS = 'remove_members',
  ADD_EXTERNAL_USERS = 'add_external_users',
  ASSIGN_TO = 'assign_to',
  ADD_WHISPER_TO_PROJECTS = 'add_whisper_to_projects',
  SET_STATUS_TO = 'set_status_to',
  NOTIFY_SELECTED_USERS = 'notify_selected_users',
  ADD_SELECTED_USERS_TO_WHISPER = 'add_selected_users_to_whisper',
  SEND_WHISPER_MESSAGE = 'send_whisper_message',
  CREATE_PROJECT_FROM_WHISPER = 'create_project_from_whisper',
  ADD_GROUPS = 'add_groups',
  REMOVE_GROUPS = 'remove_groups',
  NOTIFY_ALL_MEMBERS = 'notify_all_members',
  NOTIFY_USER_GROUPS = 'notify_user_groups',
}

export enum CONDITION_TYPE_ENUM {
  NEW_TAG_IS = 'new_tag_is',
  NEW_STATUS_IS = 'new_status_is',
  ANSWER_TO_QUESTION = 'answer_to_question',
  CHANNEL_IS = 'channel_is',
  WORKFLOW_IS = 'workflow_is',
  ANSWER_TO_WORKFLOW_QUESTION = 'answer_to_workflow_question',
  ANSWER_TO_NUMBER_QUESTION = 'answer_to_number_question',
  STEP_IS = 'step_is',
  LANGUAGE_IS = 'language_is',
  CONTAINS_KEYWORD = 'contains_keyword'
}

/**
 * @note A list of condition types where duplicates permitted
 * ie. an Advanced Automation can have n conditions with same condition type if condition type one of ...
 */
export const CONDITION_TYPE_DUPLICATE_ALLOWED = Object.freeze([
  CONDITION_TYPE_ENUM.ANSWER_TO_QUESTION,
  CONDITION_TYPE_ENUM.ANSWER_TO_WORKFLOW_QUESTION,
  CONDITION_TYPE_ENUM.ANSWER_TO_NUMBER_QUESTION,
])

/** @deprecated API conflates triggers and conditions */
export const TIMED_CONDITION_TYPE_ENUM = TIMED_TRIGGER_TYPE_ENUM


export enum AUTOMATION_ERROR_TYPE_ENUM {
  CHANNEL_NOT_LIVE = 'channel_not_live',
  ELEMENT_IS_ARCHIVED = 'element_is_archived',
  ELEMENT_OPTION_NOT_FOUND = 'element_option_not_found',
  EMPTY_KEYWORDS = 'empty_keywords',
  EMPTY_MESSAGE_CONTENT = 'empty_content',
  PROJECT_STATUS_NOT_FOUND = 'project_status_not_found',
  STEP_DOES_NOT_BELONG_TO_WORKFLOW = 'step_does_not_belong_to_workflow',
  STEP_IS_ARCHIVED = 'step_is_archived',
  USER_IS_INACTIVE = 'user_is_inactive',
  WHISPER_STATUS_NOT_FOUND = 'whisper_status_not_found',
  WORKFLOW_NOT_LIVE = 'workflow_not_live',
  CHANNEL_NOT_FOUND = 'channel_not_found',
  ELEMENT_NOT_FOUND = 'element_not_found',
  INVALID_NUMBER = 'invalid_number',
  INVALID_UNIT = 'invalid_unit',
  LOCALE_NOT_FOUND = 'locale_not_found',
  INVALID_ANSWER_NUMBER = 'invalid_answer_number',
  INVALID_OPERATOR = 'invalid_operator',
  STEP_NOT_FOUND = 'step_not_found',
  TAG_NOT_FOUND = 'tag_not_found',
  USER_GROUP_NOT_FOUND = 'user_group_not_found',
  USER_NOT_FOUND = 'user_not_found',
  WORKFLOW_NOT_FOUND = 'workflow_not_found',
  PARSING = 'error_parsing_automation',
  GROUP_CONDITION_ATTRIBUTE_EMPTY = 'group_condition_attribute_empty'
}

export enum TAGGABLE_TYPE_ENUM {
  WHISPER = 'whisper',
  PROJECT = 'project',
}

export enum PRODUCT_SOURCE_ENUM {
  PULSE_MOBILE = 'PULSE_MOBILE',
  PULSE_WEB = 'PULSE_WEB',
  SLACK = 'SLACK',
  MICROSOFT_TEAMS = 'MICROSOFT_TEAMS',
  WHISPLI_WEB = 'WHISPLI_WEB',
  WHISPLI_MOBILE = 'WHISPLI_MOBILE',
}

export enum ARCHIVABLE_TYPE_ENUM {
  PROJECT = 'projects',
  REPORT = 'whispers',
}

export enum AUDIT_TYPE_ENUM {
  CREATED = 'created',
  UPDATED = 'updated',
  ARCHIVED = 'archived',
  DELETED = 'deleted',
  READ = 'read',
  RESTORED = 'restored',
  EXPORTED = 'exported',
  SEARCHED = 'searched',
  SUBMITTED = 'submitted',
  RETENTION_POLICY_EXECUTED = 'retention-policy-executed',
  DUPLICATED = 'duplicated',
  REDACTION_SCHEDULED = 'redaction-scheduled',
  REDACTION_REMINDER_SENT = 'redaction-reminder-sent',
  REDACTION_EXECUTED = 'redaction-executed',
  REDACTION_CANCELED = 'redaction-canceled',
  EXTERNAL_USER_CREATED = 'external-user-created',
  EXTERNAL_USER_INVITED = 'external-user-invited',
  EXTERNAL_USER_UPDATED = 'external-user-updated',
  EXTERNAL_USER_ACCESSED = 'external-user-accessed',
  EXTERNAL_USER_REVOKED = 'external-user-revoked',
  USER_ROLES_UPDATED = 'user-roles-updated',
  AUTOMATION_ACTION_CREATED = 'automation-action-created',
  AUTOMATION_CONDITION_CREATED = 'automation-condition-created',
  AUTOMATION_CREATED = 'automation-created',
  AUTOMATION_ACTION_UPDATED = 'automation-action-updated',
  AUTOMATION_CONDITION_UPDATED = 'automation-condition-updated',
  AUTOMATION_UPDATED = 'automation-updated',
  AUTOMATION_ACTION_DELETED = 'automation-action-deleted',
  AUTOMATION_CONDITION_DELETED = 'automation-condition-deleted',
  AUTOMATION_DELETED = 'automation-deleted',
  AUTOMATION_GROUP_CREATED = 'automation-group-created',
  AUTOMATION_GROUP_UPDATED = 'automation-group-updated',
  AUTOMATION_GROUP_DELETED = 'automation-group-deleted',
  AUTOMATION_GROUP_CONDITION_CREATED = 'automation-group-condition-created',
  AUTOMATION_GROUP_CONDITION_UPDATED = 'automation-group-condition-updated',
  AUTOMATION_GROUP_CONDITION_DELETED = 'automation-group-condition-deleted',
  AUTOMATION_GROUP_ACTION_CREATED = 'automation-group-action-created',
  AUTOMATION_GROUP_ACTION_UPDATED = 'automation-group-action-updated',
  AUTOMATION_GROUP_ACTION_DELETED = 'automation-group-action-deleted',
}

export enum AUDITABLE_TYPE_ENUM {
  CHANNEL = 'channels',
  WORKFLOW = 'workflows',
  INVITATION = 'invitations',
  PROJECT = 'projects',
  PROJECT_WORKFLOW = 'projectWorkflows',
  REPORT = 'whispers',
  USER = 'users',
  WHISPER_WORKFLOW = 'whisperWorkflows',
  WHISPER_PARTICIPANTS = 'whisperParticipants',
  USER_WHISPER_ACTIONS = 'userWhisperActions',
  WHISPER = 'whispers',
  AUTOMATION = 'automations',
  GROUP_AUTOMATION = 'automations',
  MESSAGE = 'messages',
  COMMENT = 'comments',
  GROUP_CONDITION = 'conditions',
  GROUP_ACTION = 'actions',
  RETENTION_POLICY = 'retentionPolicies',
  PARTICIPANTS = 'participants',
}

export enum NOTIFICATION_TYPE_ENUM {
  REPORT_DOWNLOAD_SUCCESS = 'INFORMANT_REPORT_DOWNLOAD_SUCCESS',
  REPORT_DOWNLOAD_ERROR = 'INFORMANT_REPORT_DOWNLOAD_ERROR',
  REPORT_REDACTION_SCHEDULED = 'INFORMANT_REPORT_REDACTION_SCHEDULED',
  REPORT_REDACTION_DUE_IN_ONE_WEEK = 'INFORMANT_REPORT_REDACTION_DUE_IN_ONE_WEEK',
  REPORT_REDACTION_EXECUTED = 'INFORMANT_REPORT_REDACTION_EXECUTED',
  REPORT_REDACTION_CANCELED = 'INFORMANT_REPORT_REDACTION_CANCELED',
  REPORT_CLOSED = 'INFORMANT_REPORT_HAS_BEEN_CLOSED',
  REPORT_REOPENED = 'INFORMANT_REPORT_HAS_BEEN_REOPENED',
  CONVERSATION_FILE_UPLOADED = 'CONVERSATION_FILE_UPLOADED',
  CONVERSATION_FILE_DELETED = 'CONVERSATION_FILE_DELETED',
  ACTIVITY_MEMBER_ADDED = 'ACTIVITY_MEMBER_ADDED',
  ACTIVITY_MEMBER_REMOVED = 'ACTIVITY_MEMBER_REMOVED',
  ACTIVITY_NEW_REPORT_VERSION = 'ACTIVITY_NEW_REPORT_VERSION',
  ACTIVITY_OWNER_CHANGED = 'ACTIVITY_OWNER_CHANGED',
  ACTIVITY_PROJECT_ATTACHED_TO_REPORT = 'ACTIVITY_PROJECT_ATTACHED_TO_REPORT',
  ACTIVITY_PROJECT_DETACHED_TO_REPORT = 'ACTIVITY_PROJECT_DETACHED_TO_REPORT',
  ACTIVITY_REPORT_ARCHIVED = 'ACTIVITY_REPORT_ARCHIVED',
  ACTIVITY_REPORT_LOCKED = 'ACTIVITY_REPORT_LOCKED',
  ACTIVITY_REPORT_UNARCHIVED = 'ACTIVITY_REPORT_UNARCHIVED',
  ACTIVITY_REPORT_UNLOCKED = 'ACTIVITY_REPORT_UNLOCKED',
  ACTIVITY_STATUS_CHANGED = 'ACTIVITY_STATUS_CHANGED',
  ACTIVITY_TAG_ADDED = 'ACTIVITY_TAG_ADDED',
  ACTIVITY_TAG_REMOVED = 'ACTIVITY_TAG_REMOVED',
  ACTIVITY_WORKFLOW_ASSIGNED = 'ACTIVITY_WORKFLOW_ASSIGNED',
  ACTIVITY_WORKFLOW_DEACTIVATED = 'ACTIVITY_WORKFLOW_DEACTIVATED',
}

export { NOTIFICATION_TYPE_ENUM as NotificationType }

export const REPORT_ID_HEADER_KEY = 'X-Whisper-ID'
