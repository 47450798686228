import { toPath } from '@whispli/client/tenant/openapi/overrides/utils'
import type { Route } from '@whispli/client/types'

export * from './core'
export * from './whisperer'
export {
  getMe,
  postAuthWebsockets as webSocketAuth,
  postAuthTranslations as authTranslations,
  getElementTypes as elementTypes,
  getReportsIdAvatar as avatar,
  getCountries as countries,
  getFormsForm as form,
  getUsers,
  getUserGroups,
  postReportParticipantsParticipantReportFiles,
  postInformantAnswerVoices,
} from '@whispli/client/tenant/openapi/client/paths'

/**
 * @todo Await tenant API implementation
 */
export const platformsAuth: Route = {
  method: 'POST',
  path: toPath('/auth/platforms'),
}
