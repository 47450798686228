import { ReportStatus as Base } from '@whispli/client/tenant/openapi/client/model/ReportStatus'

export class ReportStatus extends Base {
  get isClosed() {
    return this.isEndType
  }

  get isArchived() {
    return Boolean(this.archivedAt)
  }
}
