import { getCodeFromResponseBody, getResponse } from '@whispli/client/tenant/openapi/error'
import {
  AppError, ERROR_ENUM, ERROR_STATUS_CODE_ENUM, ERROR_STATUS_CUSTOM_CODE_ENUM,
} from '@whispli/error'
import { isIgnoredUrl } from '@whispli/client/tenant/openapi/overrides/utils'
import { IGNORED_401_URLS } from '@whispli/client/tenant/constants'
import { useAuthStore } from '@whispli/client/tenant/auth/store'
import { __debug__ } from '@whispli/utils/debug'
import { MAINTENANCE_REDIRECT_URL } from '@whispli/types'

/**
 * @see https://tanstack.com/query/v4/docs/react/reference/useQuery
 */
export const onError = async (error?): Promise<void> => {
  const response = getResponse(error)
  const status = response?.status

  if (!status) {
    return
  }

  // Headless client; return
  if (typeof window === 'undefined') {
    return
  }

  let authStore: ReturnType<typeof useAuthStore> | null = null
  try {
    authStore = useAuthStore()
  } catch (err) {
    /** Pinia not supported */
    __debug__(err)
  }

  switch (status) {
    case ERROR_STATUS_CODE_ENUM.IDLE: {
      // @todo
      // vm.$store.dispatch('ui/inactivityWarning/show')

      return
    }
    case ERROR_STATUS_CODE_ENUM.UNAUTHORIZED: {
      if (isIgnoredUrl(response.url ?? '', IGNORED_401_URLS)) {
        return
      }

      if (authStore && typeof authStore?.logout === 'function') {
        authStore.logout().catch(() => window.location.replace('/'))
      } else {
        window.location.replace('/')
      }

      return
    }
    case ERROR_STATUS_CODE_ENUM.UNPROCESSABLE: {
      try {
        // Attempt to parse JSON and assign validation errors to `response.data`
        response.data = await response.json()
      } catch (err) {
        response.data = {
          ...err instanceof Error ? err : null,
          code: ERROR_STATUS_CODE_ENUM.UNPROCESSABLE,
        }
      }

      return
    }
    case ERROR_STATUS_CODE_ENUM.SERVICE_UNAVAILABLE: {
      try {
        const code = await getCodeFromResponseBody(response)

        switch (code) {
          case ERROR_STATUS_CUSTOM_CODE_ENUM.MAINTENANCE_MODE: {
            window.stop()
            window.location.replace(MAINTENANCE_REDIRECT_URL)
            break
          }
          case ERROR_STATUS_CUSTOM_CODE_ENUM.TRANSLATIONS_BUILDING: {
            /** Translation bundle is not built. Retry */
            break
          }
        }
      } catch (err) {
        response.data = {
          ...err instanceof Error ? err : null,
          code: ERROR_STATUS_CODE_ENUM.SERVICE_UNAVAILABLE,
        }
      }

      return
    }
  }
}

export const shouldRetry = (failureCount: number, error?: any): boolean => {
  const response = getResponse(error)

  if (failureCount === 4) {
    return false // retries exhausted
  }

  if (!response) {
    return false
  }

  // Headless client
  if (typeof window === 'undefined') {
    return false
  }

  const { url, status } = response

  switch (status) {
    case ERROR_STATUS_CODE_ENUM.IDLE:
      return true
    case ERROR_STATUS_CODE_ENUM.UNAUTHORIZED:
      return isIgnoredUrl(url ?? '', IGNORED_401_URLS)
    case ERROR_STATUS_CODE_ENUM.BAD_REQUEST:
      return false
    case ERROR_STATUS_CODE_ENUM.UNPROCESSABLE:
      return false
    case ERROR_STATUS_CODE_ENUM.SERVICE_UNAVAILABLE:
      return true
    case ERROR_STATUS_CODE_ENUM.CONNECTION_TIMED_OUT:
      /**
       * Allow retry.
       *
       * CloudFlare is reverse proxy routing requests to an Origin server
       * (in our case, an NGINX service inside a K8s cluster).
       * CloudFlare responds with error 522 if the Origin server does not ACK within 15 seconds ie. if the distance
       * between the CloudFlare data center and the Origin server is too great.
       * Especially problematic for China clients using a VPN to connect to an Origin server in China mainland from
       * a VPN server in the US.
       *
       * @see https://whispli.slack.com/archives/CHB6NJBR9/p1693543883641399?thread_ts=1693460423.154059&cid=CHB6NJBR9
       * @see https://developers.cloudflare.com/support/troubleshooting/cloudflare-errors/troubleshooting-cloudflare-5xx-errors/#error-522-connection-timed-out
       */
      return true
  }

  return true
}

